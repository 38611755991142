import React, { useState } from "react";
import FeatureSection from "../FeatureSection/FeatureSection";
import "./Features.css";
import unifiedPlatformImage from "../../assets/images/UnifiedPlatform.svg";
import faultDetectionImage from "../../assets/images/Fault detection.svg";
import maintenanceCopilotImage from "../../assets/images/Maintenance-Copilot.svg";
import predictiveAnalisisImage from "../../assets/images/Predictive-analysis.svg";

export default function Features({ scrollToDemo }) {
  const [enlargedImage, setEnlargedImage] = useState(null);

  const toggleEnlarge = (imageId) => {
    setEnlargedImage(enlargedImage === imageId ? null : imageId);
  };
  const features = [
    {
      id: "unified",
      title: "Heterogeneous Infrastructure!",
      description: (
        <React.Fragment>
          Use Asama's Unified Platform - put{" "}
          <span className="highlighted-text">
            Prompt Engineering on steroids.
          </span>{" "}
          <span>
            No more looking at multiple vendor dashboards and still struggling
            with visibility and discovery of your infrastructure! Enable faster
            response, better planning and scientific audits.
          </span>
        </React.Fragment>
      ),
      buttonText: "Dive In",
      image: unifiedPlatformImage,
      alt: "unified platform",
    },
    {
      id: "fault",
      title: "Unexpected Hardware Failure?",
      description: (
        <React.Fragment>
          Deploy the{" "}
          <span className="highlighted-text">
            industry’s best Fault detection{" "}
          </span>{" "}
          {""}
          <span>
            for your data centers using our server health monitoring tool.
            Identify component level faults in your server fleet with Asama’s
            2nd and 3rd order health metrics. Launch faster response and
            resolution with our intelligent fix recommendations, severity based
            alerting and automated RCA. Know what might break, Action it before
            it breaks.
          </span>
        </React.Fragment>
      ),
      buttonText: "Request a demo",
      image: faultDetectionImage,
      alt: "fault detection",
    },
    {
      id: "analysis",
      title: "Know what might break, Action it before it breaks.",
      description: (
        <React.Fragment>
          Use{" "}
          <span className="highlighted-text">
            Asama’s AI Predictive analytics{" "}
          </span>
          {""}
          <span>
            to your advantage - predict failures from patterns of past failures
            and our early warning mechanisms. Our predictive analytics comes
            inbuilt with an intelligent recommendation engine to provide smart &
            actionable insights.
          </span>
        </React.Fragment>
      ),
      buttonText: "Learn more",
      image: predictiveAnalisisImage,
      alt: "graph",
    },
    {
      id: "copilot",
      title: "Still managing maintenance using Excel sheets?",
      description: (
        <React.Fragment>
          Welcome "
          <span className="highlighted-text">Maintenance Co-pilot</span>
          {""}
          <span>
            !” for your Infrastructure. Use Asama AI co-pilot to plan and
            orchestrate your infrastructure maintenance without impacting your
            SLA. Server monitoring, system observability and hybrid cloud
            observability –we got it covered.{" "}
          </span>{" "}
        </React.Fragment>
      ),
      buttonText: "Talk to our co-founders",
      image: maintenanceCopilotImage,
      alt: "maintenance copilot",
    },
  ];

  return (
    <div className="features-page">
      {features.map((feature, index) => (
        <FeatureSection
          key={feature.id}
          title={feature.title}
          description={feature.description}
          buttonText={feature.buttonText}
          scrollToDemo={scrollToDemo}
          isReversed={index % 2 !== 0}
        >
          <div className="chat-message">
            <img
              src={feature.image}
              alt={feature.alt}
              className={`avatar ${
                enlargedImage === feature.id ? "enlarged" : ""
              }`}
              onClick={() => toggleEnlarge(feature.id)}
            />
          </div>
        </FeatureSection>
      ))}
      {enlargedImage && (
        <div className="overlay" onClick={() => setEnlargedImage(null)}>
          <img
            src={features.find((f) => f.id === enlargedImage).image}
            alt="enlarged"
            className="enlarged-image"
          />
        </div>
      )}
    </div>
  );
}
