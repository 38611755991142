import React from "react";
import image from "../../assets/images/How-it-works.svg";
import "./HowItWorks.css";
import image1 from "../../assets/images/HIW-1.png";
import image2 from "../../assets/images/HIW-2.png";
import image3 from "../../assets/images/HIW-3.png";

export default function HowItWorks() {
  return (
    <div className="how-it-works">
      <h2 className="work-header">AI observability - How it works</h2>
      <div className="image-container">
        <img
          src={image}
          alt="how it works with AI observability"
          className="how-it-works-avatar"
        />
      </div>
      <div className="how-it-works-text">
        <div className="work-grid">
          <div className="work-column">
            <img src={image1} alt="number 1" />
            <h3>Ingest raw data:</h3>
            <ul>
              <li>Ingest all system, hardware components and OS data.</li>
              <li>Custom metrics.</li>
              <li>Agent led collection.</li>
            </ul>
          </div>
          <div className="work-column">
            <img src={image2} alt="number 2" />
            <h3>Discover Faults and Issues:</h3>
            <ul>
              <li> Uncover component level failures.</li>
              <li>Detect OS/firmware anomalies.</li>
              <li> Identify gray failures.</li>
              <li>Complete SRE observability.</li>
            </ul>
          </div>
          <div className="work-column">
            <img src={image3} alt="number 3" />
            <h3>AI for infra teams:</h3>
            <ul>
              <li>NLP enabled interface.</li>
              <li>AI/ML led infrastructure monitoring.</li>
              <li>AI agent led remediations.</li>
              <li> Intelligent recommendation engine.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
