import React from "react";
import "./Benefits.css";
import wastageImage from "../../assets/images/reduce-hardware-wastage.png";
import time from "../../assets/images/time.png";
import capex from "../../assets/images/capex.png";

export default function Benefits() {
  return (
    <div className="benefits">
      <h1>
        Boost reliability, security and accrue cost savings through Asama.ai's
        intelligent observability
      </h1>
      <div className="benefits-grid">
        <div className="benefit-column">
          <img src={wastageImage} alt="reduce wastage" />
          <h3>90%</h3>
          <p>
            Reduction in revenue impacting core infrastructure related
            incidents.
          </p>
        </div>
        <div className="benefit-column">
          <img src={capex} alt="dollar symbol" />
          <h3>66%</h3>
          <p>Drop in CapEx investments in redundancy planning.</p>
        </div>
        <div className="benefit-column">
          <img src={time} alt="alarm clock" />
          <h3>50%</h3>
          <p>Reduction in time spent in troubleshooting and issue debugging.</p>
        </div>
      </div>
    </div>
  );
}
