import React, { useState, useRef, useEffect } from "react";
import asama from "../../assets/images/Asama-final.png";
import features from "../../assets/images/Features.png";
import workImage from "../../assets/images/how-it-works.png";
import benefits from "../../assets/images/benefits.png";
import "./Header.css";

export default function Header({
  scrollToFeatures,
  scrollToWorks,
  scrollToBenefits,
  scrollToDemo,
}) {
  const [activeLink, setActiveLink] = useState("");
  const [menuActive, setMenuActive] = useState(false);
  const menuRef = useRef();
  const menuIconRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target)
      ) {
        setMenuActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClick(link, scrollFunction, event) {
    setActiveLink(link);
    scrollFunction();
    setMenuActive(false);
  }

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header className="header">
      {/* Desktop Layout */}
      <div className="desktop-header">
        <div className="logo">
          <div
            className="logo-clickable"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setActiveLink("");
            }}
          >
            <div className="logo-image">
              <img src={asama} alt="asama.ai logo" />
            </div>
          </div>
        </div>
        <nav>
          <ul>
            <li
              className="text-emoticon"
              onClick={(e) => handleClick("features", scrollToFeatures, e)}
            >
              <img src={features} alt="check-list" />
              <a
                href="#features"
                className={activeLink === "features" ? "active" : ""}
              >
                Features
              </a>
            </li>
            <li
              className="text-emoticon"
              onClick={(e) => handleClick("howItWorks", scrollToWorks, e)}
            >
              <img src={workImage} alt="how-it-works img" />
              <a
                href="#HowItWorks"
                className={activeLink === "howItWorks" ? "active" : ""}
              >
                How it works
              </a>
            </li>
            <li
              className="text-emoticon"
              onClick={(e) => handleClick("benefits", scrollToBenefits, e)}
            >
              <img src={benefits} alt="benefits img" />
              <a
                href="#Benefits"
                className={activeLink === "benefits" ? "active" : ""}
              >
                Benefits
              </a>
            </li>
            <li>
              <button
                className="header-button"
                onClick={() => {
                  scrollToDemo();
                  setActiveLink("");
                }}
              >
                Book a demo
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Mobile Layout */}
      <div className="mobile-header">
        <div className="logo">
          <img src={asama} alt="asama.ai logo" />
        </div>
        <div className="mobile-actions">
          <button
            className="header-button"
            onClick={() => {
              scrollToDemo();
              setActiveLink("");
            }}
          >
            Book a demo
          </button>
          <div className="menu-icon" ref={menuIconRef} onClick={toggleMenu}>
            ☰
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <nav
        className={`mobile-menu ${menuActive ? "active" : ""}`}
        ref={menuRef}
      >
        <ul>
          <li onClick={(e) => handleClick("features", scrollToFeatures, e)}>
            <a
              href="#features"
              className={activeLink === "features" ? "active" : ""}
            >
              Features
            </a>
          </li>
          <li onClick={(e) => handleClick("howItWorks", scrollToWorks, e)}>
            <a
              href="#HowItWorks"
              className={activeLink === "howItWorks" ? "active" : ""}
            >
              How it works
            </a>
          </li>
          <li onClick={(e) => handleClick("benefits", scrollToBenefits, e)}>
            <a
              href="#Benefits"
              className={activeLink === "benefits" ? "active" : ""}
            >
              Benefits
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
