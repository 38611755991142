import React from "react";
import image from "../../assets/images/Asama-final.png";
import "./Footer.css";
import ReactGA from "react-ga4";
import rectangle from "../../assets/images/rectangle.png";

export default function Footer() {
  const trackExternalLink = (label) => {
    ReactGA.event({
      category: "External Link",
      action: "Click",
      label: label,
    });
  };
  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={image} alt="ASAMA logo" />
        <p> Observability Reimagined. Remediation Reinforced</p>
      </div>
      <div className="footer-columns">
        <div className="footer-links">
          <div className="footer-column">
            <img src={rectangle} alt="rectangle" />
            <h3>
              Bangalore
              <br />
              London
              <br />
              New York
              <br />& Beyond
            </h3>
          </div>
          <div className="footer-column">
            <img src={rectangle} alt="rectangle" />
            <h3>Connect</h3>
            <ul>
              <li>
                <a
                  href="mailto:contactus@asama.ai"
                  onClick={() => trackExternalLink("Email")}
                >
                  Email
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/asama/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackExternalLink("LinkedIn")}
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <img src={rectangle} alt="rectangle" />
            <h3>Company</h3>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/asama/posts/?feedView=all"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => trackExternalLink("News")}
                >
                  News
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright">
          <p>© 2024 Asama.ai inc. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
