import React from "react";
import "./Demo.css";

export default function Demo() {
  return (
    <div className="calendar-container">
      <h1>Talk to the co-founders</h1>
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ05pBBccN_SZSJhvJeBpjyBatHANZPapxI1yhQtjYYA3M1GUMHc68SD84dZQ8lmau3hkzGwED02?gv=true"
        style={{ border: 0 }}
        width="800"
        height="800"
        title="Demo Scheduling Calendar"
      ></iframe>
    </div>
  );
}
