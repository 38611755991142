import React, { useRef } from "react";
import Header from "../Header/Header";
import Features from "../Features/Features";
import HowItWorks from "../HowItWorks/HowItWorks";
import Benefits from "../Benefits/Benefits";
import Demo from "../Demo/Demo";
import Footer from "../Footer/Footer";
import ReactGA from "react-ga4";
import "./HomePage.css";

export default function HomePage() {
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const benefitsRef = useRef(null);
  const demoRef = useRef(null);
  function scrollToFeatures() {
    featuresRef.current?.scrollIntoView({ behavior: "smooth" });
    ReactGA.event({
      category: "User",
      action: "Scrolled to Features",
    });
  }
  function scrollToWorks() {
    howItWorksRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    ReactGA.event({
      category: "User",
      action: "Scrolled to How It Works",
    });
  }
  function scrollToDemo() {
    demoRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    ReactGA.event({
      category: "User",
      action: "Scrolled to Demo",
    });
  }
  function scrollToBenefits() {
    benefitsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    ReactGA.event({
      category: "User",
      action: "Scrolled to Benefits",
    });
  }
  return (
    <div className="main-web-page">
      <Header
        scrollToFeatures={scrollToFeatures}
        scrollToWorks={scrollToWorks}
        scrollToBenefits={scrollToBenefits}
        scrollToDemo={scrollToDemo}
      />
      <main>
        <section className="home-page">
          <h1>See deeper, fix faster </h1>
          <h2>AI-powered infrastructure clarity.</h2>
          <p>
            Deeper Observability. Actionable Insights. Automated Remediations.
            Better Management.
          </p>
          <div className="cta-buttons">
            <button
              className="secondary-cta"
              onClick={(e) => {
                e.preventDefault();
                scrollToDemo();
                ReactGA.event({
                  category: "User",
                  action: "Clicked Book a Demo",
                });
              }}
            >
              Meet the co-founders
            </button>
          </div>
        </section>
        <section id="features" ref={featuresRef}>
          <Features scrollToDemo={scrollToDemo} />
        </section>
        <section id="howItWorks" ref={howItWorksRef}>
          <HowItWorks />
        </section>
        <section id="benefits" ref={benefitsRef}>
          <Benefits />
        </section>
        <section id="demo" ref={demoRef}>
          <Demo />
        </section>
      </main>
      <Footer />
    </div>
  );
}
