import React from "react";
import ReactGA from "react-ga4";

export default function FeatureSection({
  title,
  description,
  buttonText,
  isReversed,
  children,
  scrollToDemo,
}) {
  return (
    <div className={`feature-section ${isReversed ? "reversed" : ""}`}>
      <div className="feature-content">
        <h2 className="feature-title">{title}</h2>
        <p className="feature-description">{description}</p>
        {buttonText && (
          <button
            className="feature-button"
            onClick={(e) => {
              e.preventDefault();
              scrollToDemo();
              ReactGA.event({
                category: "User",
                action: "Clicked Book a Demo",
              });
            }}
          >
            {buttonText}
          </button>
        )}
      </div>
      <div className="feature-visual">{children}</div>
    </div>
  );
}
